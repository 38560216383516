import {
  AboutMeTitle,
  Description,
  Wrapper,
  LeftWrapper,
  RightWrapper,
  DescriptionWrapper,
  ContentWrapper,
  AboutMeTitleWrapper,
  ImageWrapper,
  Image,
  SlideContent,
  SlideWrapper,
  SlideItemWrapper,
  StyledFontAwesomeIcon,
  PageWrapper,
  SlideDetails,
} from "./About.styles";
import RevealEffect from "components/reveal-effect/RevealEffect";
import { Swiper, SwiperSlide } from "swiper/react";
import Tilt from "react-parallax-tilt";

import "swiper/css";
import useViewport from "hooks/useViewport/useViewport";
import {
  faBriefcase,
  faCertificate,
  faGraduationCap,
} from "@fortawesome/free-solid-svg-icons";

export const About: React.FC = () => {
  const { extraSmall, small } = useViewport();

  const slideText = {
    icon: faBriefcase,
    details: "5",
    description: "Years of Experience",
  };

  const slideText2 = {
    icon: faGraduationCap,
    details: "1",
    description: "Bachelor of Technology",
  };

  const slideText3 = {
    icon: faCertificate,
    details: "B2",
    description: "Certified English Level",
  };

  const slideText4 = {
    icon: faCertificate,
    details: "6",
    description: "Certifications",
  };

  const slideList = [slideText, slideText2, slideText3];

  const description = `My interest and <strong>passion for technology</strong> ignited early in my life. I started using computers at the <strong>age of 5</strong> and immersed in programming by the <strong>age of 12</strong>.
  Embracing <strong>my creativity</strong>, I developed a passion for <strong>web development</strong>, with a meticulous <strong>eye for detail</strong>, I carefully review every pixel on the screen to improve and deliver <strong>my best work</strong>.<br>
  <strong> My motivation </strong> lies in creating something that is not only <strong>aesthetically pleasing</strong> but also <strong>highly functional</strong>, with the goal of providing the <strong>best possible user experience</strong>.<br/>
  One thing that sets me apart is the <strong>integration of the principles</strong> learned from my experience as a <strong>Karate Black Belt</strong> into both my <strong>personal and professional life</strong>. Through years of dedicated training, I've not only enhanced my physical health but also cultivated a strong <strong>sense of 
  discipline</strong>, <strong>mental focus</strong>, and an <strong>open mind</strong>, helping me constantly strive for <strong>excellence</strong> and remain committed to the <strong>continuous improvement</strong> of my work.
  Currently, I work as a <strong>Front-End Developer</strong> with over <strong>5 years of experience</strong> and hold a degree in <strong>System Analysis and Development</strong>.
  `;

  const SwiperComponent = () => (
    <SlideWrapper>
      <Swiper slidesPerView={extraSmall ? 2 : 3}>
        {slideList.map((slide, index) => (
          <SwiperSlide key={index}>
            <RevealEffect
              effect="fade"
              delay={200 * index}
              direction="down"
              duration={1000}
            >
              <SlideItemWrapper
                isFirst={index === 0}
                isLast={index === slideList.length - 1}
                className="shadow-md"
              >
                <SlideContent>
                  <StyledFontAwesomeIcon icon={slide.icon} />
                  <SlideDetails className="bold">{slide.details}</SlideDetails>
                  <p>{slide.description}</p>
                </SlideContent>
              </SlideItemWrapper>
            </RevealEffect>
          </SwiperSlide>
        ))}
      </Swiper>
    </SlideWrapper>
  );

  return (
    <PageWrapper>
      <Wrapper>
        <ContentWrapper>
          <LeftWrapper>
            <div>
              <RevealEffect effect="fade" direction="down">
                <AboutMeTitleWrapper>
                  <AboutMeTitle>About Me</AboutMeTitle>
                  <h2>Who is Luccas?</h2>
                </AboutMeTitleWrapper>
              </RevealEffect>
              <RevealEffect
                delay={1e3}
                duration={1500}
                cascade
                damping={1e-1}
                direction="down"
              >
                <DescriptionWrapper>
                  <Description
                    dangerouslySetInnerHTML={{ __html: description }}
                  />
                  <SwiperComponent />
                </DescriptionWrapper>
              </RevealEffect>
            </div>
          </LeftWrapper>
          <RightWrapper>
            <ImageWrapper>
              <Tilt
                className="section2-img trainers active animate animate2"
                tiltReverse
                tiltMaxAngleX={5}
                tiltMaxAngleY={5}
                tiltEnable={!extraSmall && !small}
              >
                <RevealEffect
                  effect="fade"
                  direction="up"
                  delay={!extraSmall ? 1200 : undefined}
                >
                  <Image
                    draggable={false}
                    alt="me"
                    src="images/picture_face.jpg"
                  />
                </RevealEffect>
              </Tilt>
            </ImageWrapper>
          </RightWrapper>
        </ContentWrapper>
      </Wrapper>
    </PageWrapper>
  );
};
