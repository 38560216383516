import { css, keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import { media } from "utils/custom-media";

type StyledProps = {
  isHovered?: boolean;
};

export const Wrapper = styled.div`
  padding: 0 10% 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${media.lteSmallMedia} {
    padding: 0 5% 0;
  }
`;

export const ContentWrapper = styled.div`
  width: 100%;
  margin-top: 10px;
`;

export const Title = styled.span`
  font-size: 24px;
  text-transform: uppercase;
  font-weight: 300;
  font-family: var(--font-regular);
`;

export const SubTitle = styled.h2`
  margin-bottom: 10px;
`;

export const Description = styled.p`
  margin-bottom: 20px;
  font-family: var(--font-regular);
`;

const fadeInUp = keyframes`
0% {
  opacity: 0;
  -webkit-transform: translateY(-20px)
}

100% {
  opacity: 1;
  -webkit-transform: translateY(0)
}
`;

export const ProjectWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 300px);
  gap: 20px; /* Adjust the gap between items as needed */

  & > div:not(:last-child) {
    // margin-right: 50px;
  }

  ${media.smallMedia} {
    & > div:not(:last-child) {
      // margin-right: 15px;
    }
  }

  ${media.mediumMedia} {
    & > div:not(:last-child) {
      // margin-right: 25px;
    }
  }
`;

export const ProjectBox = styled.div`
  max-width: 100%;
  height: auto;
  position: relative;
  margin-bottom: 20px;

  ${media.extraSmallMedia} {
    margin-bottom: 50px;
  }
`;

export const DescriptionWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  // margin-top: 10px;
  margin-bottom: 5px;
  gap: 10px;
`;

export const DescriptionLink = styled.a`
  background-color: var(--primary-color);
  color: white;
  padding: 10px;
  border-radius: 8px;
  border: 2px solid var(--primary-color);
  position: relative;
  width: fit-content;
  min-width: 80px;
  text-align: center;
  font-family: var(--font-bold);
  transition-property: background-color, color;
  transition-duration: 1s;

  &:hover {
    color: var(--primary-color);
    background: transparent;
  }
`;

export const ProjectDescription = styled("div", {
  shouldForwardProp: (prop: string): boolean => prop !== "isHovered",
})<StyledProps>`
  position: absolute;
  z-index: 1;
  bottom: 0;
  text-align: left;
  padding: 20px;
  background-color: rgba(250, 250, 250, 0.95);
  width: 100%;
  height: 60px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  transition: all 0.4s ease;
  animation-duration: 1s;
  animation-fill-mode: both;
  animation: ${fadeInUp};

  h4 {
    margin-bottom: 0;
    font-size: 16px;
    margin-top: 0px;
    color: black;
    text-transform: uppercase;
  }

  p {
    opacity: 0;
    position: absolute;
    line-height: 1.5;
  }

  ${media.smallMedia} {
    p {
      font-size: 14px;
    }
  }

  ${({ isHovered }) =>
    isHovered &&
    css`
      border-radius: 10px;
      height: 100%;
      background-color: rgba(250, 250, 250, 0.9);
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-right: 10px;

      p {
        position: relative;
        opacity: 1;
        color: #4a4a4a;
      }

      h4 {
        color: #1e1e1e;
        margin-bottom: 20px;
      }
    `}
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  pointer-events: none;
  user-select: none;
  border-radius: 10px;
`;
